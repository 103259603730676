MoM<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div>
            <b-card class="mt-3" :header="$t('common.group.base')">
                <b-card-text>
                    <b-row>
                        <b-col sm="3">
                            <b-form-group :label="$t(labelPrefix+'customerType')">
                                <multiselect v-model="form.customerType"
                                             name="customerType"
                                             :searchable="true"
                                             :multiple="true"
                                             :options="getCustomerTypes()"
                                             select-label=""
                                ></multiselect>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group :label="$t(labelPrefix + 'customerForm')">
                                <b-form-radio v-for="(option,key) in getCustomerForms()"
                                              :key="key"
                                              v-model="form.customerForm"
                                              name="customerForm"
                                              :value="option"
                                              :inline="true"
                                              size="lg"
                                >{{ $t(labelPrefix + 'radio.' + option) }}
                                </b-form-radio>
                            </b-form-group>
                        </b-col>
                        <b-col sm="2">
                            <zw-switch-group v-model="form.active"
                                             name="active"
                                             :label-prefix="labelPrefix"
                            ></zw-switch-group>
                        </b-col>

                        <custom-slot id="customer_base_group" :base="getThis()"></custom-slot>
                    </b-row>
                </b-card-text>
            </b-card>

            <b-card class="mt-3"
                    :header="$t('common.group.contacts')"
            >
                <b-card-text>
                    <b-row v-if="form.customerForm == 'company'">
                        <b-col sm="3">
                            <zw-input-group v-model="form.company"
                                            name="company"
                                            :label-prefix="labelPrefix"
                                            icon="briefcase"
                                            validate="required"
                                            inputClass="modal_open_focus"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                    <b-row v-else>
                        <b-col sm="3">
                            <zw-select-group v-model="form.salutation"
                                             :options="getSalutations()"
                                             name="salutation"
                                             :label-prefix="labelPrefix"
                                             icon="address-card"
                            ></zw-select-group>
                        </b-col>

                        <b-col sm="3">
                            <zw-input-group v-model="form.firstname"
                                            name="firstname"
                                            :label-prefix="labelPrefix"
                                            icon="address-card"
                                            validate="required"
                            ></zw-input-group>
                        </b-col>

                        <b-col sm="3">
                            <zw-input-group v-model="form.lastname"
                                            icon="address-card"
                                            name="lastname"
                                            :label-prefix="labelPrefix"
                                            validate="required"
                            ></zw-input-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="2">
                            <label>{{ $t(labelPrefix + 'autoApiCustomerId') }}</label>
                            <b-form-checkbox
                                :label-prefix="labelPrefix"
                                name="autoApiCustomerId"
                                v-model="form.autoApiCustomerId"
                                @change="changeAuto()"
                                size="lg"
                            >
                            </b-form-checkbox>
                        </b-col>
                        <b-col sm="3">
                            <zw-input-group v-model="form.apiCustomerId"
                                            icon="user-cog"
                                            name="apiCustomerId"
                                            :label-prefix="labelPrefix"
                                            :readonly="isauto"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

            <b-card class="mt-3" :header="$t('common.group.address')+' (Default)'">
                <b-card-text>
                    <b-row>
                        <b-col sm="3">
                            <zw-input-group v-model="form.street"
                                            icon="map-marked-alt"
                                            name="street"
                                            :label-prefix="labelPrefix"
                                            validate="required"
                            ></zw-input-group>
                        </b-col>
                        <b-col sm="3">
                            <zw-input-group v-model="form.careOfAddress"
                                            icon="map-marked-alt"
                                            name="careOfAddress"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col sm="3">
                            <zw-input-group v-model="form.postCode"
                                            icon="map-marked-alt"
                                            name="postCode"
                                            :label-prefix="labelPrefix"
                                            :validate="{ required: true }"
                            ></zw-input-group>
                        </b-col>

                        <b-col sm="3">
                            <zw-input-group v-model="form.city"
                                            name="city"
                                            :label-prefix="labelPrefix"
                                            icon="city"
                                            :validate="{ required: true }"
                            ></zw-input-group>
                        </b-col>
                        <b-col sm="3">
                            <zw-select-group v-model="form.countryCode"
                                             :options="getCountries()"
                                             name="country"
                                             :label-prefix="labelPrefix"
                                             icon="globe-europe"
                                             text-field="name"
                                             value-field="iso2"
                                             rules="required"
                            ></zw-select-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

            <b-card>
                <b-card-text>
                    <custom-fields-block v-model="form.customValues"
                                         :fields="form.customFields"
                                         model="customer"
                                         :form="form"
                    />
                </b-card-text>
            </b-card>

            <b-row>
                <b-col sm="3"></b-col>
                <b-col sm="3">
                    <b-button block @click="onSubmit(true,false)" variant="primary">
                        {{ $t('common.button.saveAndCreateOffering') }}
                    </b-button>
                </b-col>
                <b-col sm="3">
                    <b-button block @click="onSubmit(false,false)" variant="primary">
                        {{ $t('common.button.save') }}
                    </b-button>
                </b-col>
                <b-col sm="3">
                    <b-button block @click="onSubmit(false,true)" variant="info">
                        {{ $t('common.button.save_and_stay') }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from "vuex";
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ValidateMixin from "@/mixins/validate";
import Draggable from "vuedraggable";
import CustomFieldsBlock from "@/components/CustomFieldsBlock";

export default {
    name: 'customer-form',
    components: {
        CustomFieldsBlock,
        Draggable
    },
    mixins: [ValidateMixin, commonSave],
    props: {
        customerId: {
            type: Number,
        },
        sidebar: {}
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'customer.label.',
            isauto: true,
            form: {
                autoApiCustomerId: true,
                customerType: [],
                customFields: {},
                customerForm: 'company'
            },
            defaultForm: {
                active: 1,
            },
        }
    },
    mounted() {
        this.shown(this.customerId)
    },
    methods: {
        ...mapGetters('Customer', ['getCustomer']),
        ...mapGetters('CommonData', ['getSalutations', 'getCountries', 'getCustomerTypes', 'getCustomerForms', 'getMe']),
        shown(id) {
            this.$store.dispatch('Customer/fetchCustomer', id)
                .then(() => {
                    this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getCustomer()}))
                    this.$set(this.form, 'customerType', this.form.customerType ? this.form.customerType.split(',') : ['client'])
                    this.form.autoApiCustomerId = this.form.autoApiCustomerId || typeof this.form.autoApiCustomerId == 'undefined' ? true : false;
                    this.isauto = this.form.autoApiCustomerId
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        changeAuto() {
            this.isauto = this.form.autoApiCustomerId;
            if (this.isauto) {
                this.form.apiCustomerId = null;
            }
        },
        onSubmit(openOffering = false, stay = false) {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let formData = JSON.parse(JSON.stringify(this.form))
                    formData.customerType = formData.customerType.join(',')

                    this.$store.dispatch('Customer/saveCustomer', formData)
                        .then((response) => {
                            if (!stay) {
                                this.commonAfterSave(response, this.sidebar)

                                if (openOffering) {
                                    setTimeout(() => {
                                        this.$root.$children[0].openModal('project-form', {customer: response.data}, null, {width: '800px'})
                                    }, 500);
                                }
                            } else {
                                this.$root.$children[0].updateFormValues('customer-modal')
                                this.$emit("updateCustomerId", response.data.id);
                                this.shown(response.data.id)
                            }
                        })
                        .catch(errors => {
                            if (errors) {
                                this.loading = false

                                this.$refs['observer'].setErrors(errors)
                            }
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        getThis() {
            return this
        },
    }
}
</script>